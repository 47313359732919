<template>
  <div>
    <b-card no-body class="rounded-0 mb-1">
      <b-card-header header-tag="header" class="p-3 bg-light border-bottom-0" role="tab">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-block w-100" v-b-toggle="'accordion-' + index">
            <i class="fad fa-sort-up draggable-sort-item" />
            <span class="pl-2 font-weight-light h6" v-if="!item.kol_k_no && item.tip != 'ozel'">Menu Başlık </span>
            <span class="pl-2 font-weight-light h6" v-else-if="item.kol_k_no">
              {{ handlerItemChange(item.tip, item.kol_k_no) }}
            </span>
            <span class="pl-2 font-weight-light h6" v-else-if="item.tip == 'ozel'">{{ item.icerik[defaultDil].baslik }}</span>
          </div>
          <b-button-group>
            <!-- <b-button size="sm" squared variant="warning" @click="">
              <i class="fad fa-pencil" />
            </b-button> -->
            <b-button size="sm" squared variant="info" @click="$emit('handlerSubMenuEkle', { konum, k_no: item.k_no })">
              <i class="fad fa-plus" />
            </b-button>
          </b-button-group>
        </div>
      </b-card-header>
      <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit(item))" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12" md="6">
                  <validation-provider name="Menu Tipi" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Menu Tipi" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                      <v-select
                        v-model="item.tip"
                        :options="tipler"
                        :reduce="(tip) => tip.id"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                        @input="item.kol_k_no = null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider name="Menu Türü" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Menu Türü ( seflink )" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                      <v-select
                        v-model="item.turu"
                        :options="Turler"
                        :reduce="(tip) => tip.value"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" v-if="item.tip == 'kategori'">
                  <b-form-group label="Kategoriler">
                    <treeselect
                      :options="kategoriler"
                      v-model="item.kol_k_no"
                      :normalizer="normalizer"
                      :sort-value-by="sortValueBy"
                      :default-expand-level="1"
                      noChildrenText="Alt Öge Yok."
                      placeholder="Lütfen Kategori Seçiniz"
                      aria-describedby="input-1-live-feedback"
                      class="rounded-0"
                      :max-height="200"
                    />
                    <!-- <v-select
                      v-model="item.kol_k_no"
                      :options="kategoriler"
                      :reduce="(kategori) => kategori.k_no"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                    </v-select> -->
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-else-if="item.tip == 'bolge'">
                  <b-form-group label="Sayfalar" v-if="seciliBolgeler.length > 0">
                    <v-select
                      v-model="item.kol_k_no"
                      :options="seciliBolgeler"
                      :reduce="(sayfa) => sayfa.k_no"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }} ({{ option.urun }})
                      </template>
                      <template slot="option" slot-scope="option"> {{ option.icerik[defaultDil].baslik }} ({{ option.urun }}) </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-else-if="item.tip == 'sayfa'">
                  <b-form-group label="Sayfalar">
                    <v-select
                      v-model="item.kol_k_no"
                      :options="sayfalar"
                      :reduce="(sayfa) => sayfa.k_no"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-else-if="item.tip == 'ozel'">
                  <b-row>
                    <b-col cols="12" md="6" v-show="diller.length != 1">
                      <b-form-group label="Dil">
                        <v-select
                          v-model="defaultDil"
                          :options="diller"
                          :reduce="(diller) => diller.lang"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block select-size-lg"
                          :disabled="!item.k_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="İcon">
                        <b-form-input
                          size="lg"
                          class="rounded-0"
                          ref="baslik"
                          v-model="item.icon"
                          aria-describedby="input-1-live-feedback"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group label="Başlık">
                          <b-form-input
                            size="lg"
                            class="rounded-0"
                            ref="baslik"
                            v-model="item.icerik[defaultDil].baslik"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                            @input="handlerBaslikChange"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider name="URL" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group label="URL">
                          <b-form-input
                            size="lg"
                            class="rounded-0"
                            ref="baslik"
                            v-model="item.icerik[defaultDil].url"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Slug (Seflink)">
                        <b-form-input size="lg" class="rounded-0" ref="video" v-model="item.icerik[defaultDil].slug" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label-cols="5" label="Yeni Sekme">
                    <b-form-checkbox class="float-right" :checked="item.sekme" v-model="item.sekme" switch size="lg" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label-cols="5" label="Seo Optimize">
                    <b-form-checkbox class="float-right" :checked="item.seo" v-model="item.seo" switch size="lg" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <div class="d-flex">
                    <b-button squared block type="submit" size="lg" variant="warning" class="mr-2">
                      <i class="fad fa-save pr-2"></i>
                      <span class="align-middle">KAYDET</span>
                    </b-button>
                    <b-button squared size="lg" variant="danger" @click="handlerRemove(item.k_no)">
                      <i class="fad fa-trash"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-collapse>
    </b-card>

    <draggable v-model="item.children" tag="ul" class="list-group" handle=".draggable-sort-item" @end="$emit('siraChange', item.children)">
      <b-card no-body class="rounded-0 mb-1 ml-5" v-for="(sub, subindex) in item.children" :key="subindex">
        <b-card-header header-tag="header" class="p-3 bg-light border-bottom-0" role="tab" v-b-toggle="'subacordion-' + index + subindex">
          <i class="fad fa-sort-up draggable-sort-item" />
          <span class="pl-2 font-weight-light h6" v-if="!sub.kol_k_no && sub.tip != 'ozel'">Menu Başlık </span>
          <span class="pl-2 font-weight-light h6" v-else-if="sub.kol_k_no">{{ handlerItemChange(sub.tip, sub.kol_k_no) }}</span>
          <span class="pl-2 font-weight-light h6" v-else-if="sub.tip == 'ozel'">{{ sub.icerik[defaultDil].baslik }}</span>
        </b-card-header>
        <b-collapse :id="'subacordion-' + index + subindex" accordion="sub-accordion" role="tabpanel">
          <b-card-body>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onSubmit(sub))" @reset.prevent="resetForm" autocomplete="off">
                <b-row>
                  <b-col cols="12" md="6">
                    <validation-provider name="Menu Tipi" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group label="Menu Tipi" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                        <v-select
                          v-model="sub.tip"
                          :options="tipler"
                          :reduce="(tip) => tip.id"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block select-size-lg"
                          :class="{ 'is-invalid': !!validationContext.errors[0] }"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                          @input="sub.kol_k_no = null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6">
                    <validation-provider name="Menu Türü" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group label="Menu Türü ( seflink )" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                        <v-select
                          v-model="sub.turu"
                          :options="Turler"
                          :reduce="(tip) => tip.value"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block select-size-lg"
                          :class="{ 'is-invalid': !!validationContext.errors[0] }"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" v-if="sub.tip == 'kategori'">
                    <b-form-group label="Kategoriler">
                      <treeselect
                        :options="kategoriler"
                        v-model="sub.kol_k_no"
                        :normalizer="normalizer"
                        :sort-value-by="sortValueBy"
                        :default-expand-level="1"
                        noChildrenText="Alt Öge Yok."
                        placeholder="Lütfen Kategori Seçiniz"
                        aria-describedby="input-1-live-feedback"
                        class="rounded-0"
                        :max-height="200"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" v-else-if="sub.tip == 'bolge'">
                    <b-form-group label="Sayfalar" v-if="seciliBolgeler.length > 0">
                      <v-select
                        v-model="sub.kol_k_no"
                        :options="seciliBolgeler"
                        :reduce="(sayfa) => sayfa.k_no"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                      >
                        <template slot="selected-option" slot-scope="option">
                          {{ option.icerik[defaultDil].baslik }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.icerik[defaultDil].baslik }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-else-if="sub.tip == 'sayfa'">
                    <b-form-group label="Sayfalar" v-if="sayfalar.length > 0">
                      <v-select
                        v-model="sub.kol_k_no"
                        :options="sayfalar"
                        :reduce="(sayfa) => sayfa.k_no"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                      >
                        <template slot="selected-option" slot-scope="option">
                          {{ option.icerik[defaultDil].baslik }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.icerik[defaultDil].baslik }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-else-if="sub.tip == 'ozel'">
                    <b-row>
                      <b-col cols="12" md="6" v-show="diller.length != 1">
                        <b-form-group label="Dil">
                          <v-select
                            v-model="defaultDil"
                            :options="diller"
                            :reduce="(diller) => diller.lang"
                            label="title"
                            :clearable="false"
                            class="invoice-filter-select d-block select-size-lg"
                            :disabled="!sub.k_no"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="İcon">
                          <b-form-input
                            size="lg"
                            class="rounded-0"
                            ref="baslik"
                            v-model="sub.icon"
                            aria-describedby="input-1-live-feedback"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
                          <b-form-group label="Başlık">
                            <b-form-input
                              size="lg"
                              class="rounded-0"
                              ref="baslik"
                              v-model="sub.icerik[defaultDil].baslik"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-1-live-feedback"
                              @input="handlerBaslikChange"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12">
                        <validation-provider name="URL" :rules="{ required: true }" v-slot="validationContext">
                          <b-form-group label="URL">
                            <b-form-input
                              size="lg"
                              class="rounded-0"
                              ref="baslik"
                              v-model="sub.icerik[defaultDil].url"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-1-live-feedback"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Slug (Seflink)">
                          <b-form-input size="lg" class="rounded-0" ref="video" v-model="item.icerik[defaultDil].slug" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group class="mt-4" label-cols="5" label="Yeni Sekme">
                      <b-form-checkbox class="float-right" :checked="sub.sekme" v-model="sub.sekme" switch size="lg" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group class="mt-4" label-cols="5" label="Seo Optimize">
                      <b-form-checkbox class="float-right" :checked="sub.seo" v-model="sub.seo" switch size="lg" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <div class="d-flex">
                      <b-button squared block type="submit" size="lg" variant="warning" class="mr-2">
                        <i class="fad fa-save pr-2"></i>
                        <span class="align-middle">KAYDET</span>
                      </b-button>
                      <b-button squared size="lg" variant="danger" @click="handlerRemove(sub.k_no)">
                        <i class="fad fa-trash"></i>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-collapse>
      </b-card>
    </draggable>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import draggable from 'vuedraggable';
import { defineComponent, ref, computed, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import slugify from 'slugify';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default defineComponent({
  components: { draggable, vSelect, Treeselect },
  props: {
    type: {
      type: String,
      required: true,
      default: 'web',
    },
    konum: [String],
    index: [Number],
    item: {
      type: Object,
      required: true,
    },
    kategoriler: {
      type: Array,
      required: true,
    },
    sayfalar: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const tumKategoriler = computed(() => store.getters.getTumKategoriler);
    expo.seciliBolgeler = computed(() => store.getters.getSeciliBolgeler);

    const { type, item, kategoriler, sayfalar } = toRefs(props);

    expo.tipler = ref([
      {
        id: 'kategori',
        title: 'Kategori',
      },
      {
        id: 'bolge',
        title: 'Bölge',
      },
      {
        id: 'sayfa',
        title: 'Sayfa',
      },
      {
        id: 'ozel',
        title: 'Özel URL',
      },
    ]);

    expo.Turler = ref([
      { title: 'Sayfa', value: 'sayfa' },
      { title: 'Kategori', value: 'kategori' },
      { title: 'Bölge', value: 'bolge' },
      { title: 'Ürün', value: 'urun' },
      { title: 'Transfer', value: 'transfer' },
      { title: 'Arac Kiralama', value: 'arac_kira' },
      { title: 'Blog', value: 'blog' },
      { title: 'Galeri', value: 'galeri' },
    ]);

    expo.sortValueBy = ref('INDEX');

    expo.normalizer = (node) => {
      return {
        id: node.k_no,
        label: node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.handlerItemChange = (tip, k_no) => {
      let baslik = null;
      if (k_no) {
        if (tip == 'kategori') {
          baslik = tumKategoriler.value.find((x) => x.k_no == k_no);
          return baslik.icerik[expo.defaultDil.value].baslik;
        } else if (tip == 'sayfa') {
          baslik = sayfalar.value.find((x) => x.k_no == k_no);
          return baslik.icerik[expo.defaultDil.value].baslik;
        } else if (tip == 'bolge') {
          baslik = expo.seciliBolgeler.value.find((x) => x.k_no == k_no);
          return baslik.icerik[expo.defaultDil.value].baslik;
        }
      }
    };

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      item.value.icerik[expo.defaultDil.value].slug = slug;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = (item) => {
      context.emit('show', true);
      const base_url = type.value == 'web' ? 'menuGuncelle' : 'menuAcenteGuncelle';
      store
        .dispatch(base_url, item)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı.', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message, {
              position: 'bottom-left',
            });
          }
        });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          const base_url = type.value == 'web' ? 'menuSil' : 'menuAcenteSil';
          store.dispatch(base_url, k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
              context.emit('refresh', true);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.is-invalid {
  border-color: red !important;
}
</style>
